.personTeaser {}

.header {
  font-family: var(--heading-font);
  display: flex;
  margin-top: 16px;
  margin-bottom: 6px;
}

.description {
  font-size: 1rem;
  font-weight: 400;
  max-width: 450px;
  margin-bottom: 0.1rem;
}

.tags {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: center;
  margin-left: 16px;
}

.name {
  font-size: 1.625rem;
  line-height: 130%;
  &, &:hover {
    color: var(--color);
  }
}
