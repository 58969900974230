.container {
  --pictureSize: 90px;
  &, & > a {
    width: var(--pictureSize);
    height: var(--pictureSize);
  }
  a {
    display: block;
  }
}

.containerMedium {
  --pictureSize: 100px;
  &, & > a {
    width: var(--pictureSize);
    height: var(--pictureSize);
  }
  a {
    display: block;
  }
}

.image {
  width: var(--pictureSize);
  height: var(--pictureSize);
  border-radius: var(--pictureSize);
  border: 2px solid var(--color) !important;
}

.pictureStub {
  width: var(--pictureSize);
  height: var(--pictureSize);
  border: 2px solid var(--color);
  border-radius: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: conic-gradient(from 225.64deg at 72.55% 37.49%, #FF004E 0deg, #2C72FF 46.8deg, #205EDB 120deg, #205EDB 180deg, #193DB7 240deg, #4471FF 300deg, #101586 360deg);
}

.initials {
  color: var(--white);
  text-shadow: 2px 2px 5px #071039;
  font-size: 2.25rem;
  line-height: 75%;
  font-weight: 400;
}

.link {
  &:hover {
    text-decoration: none;
  }
}
