.wrapper {
  display: inline-block;
  width: 170px;
  font-size: 0.8rem;
  text-align: center;
  padding-left: 15px;
  padding-right: 5px;

  svg {
    position: relative;
    fill: var(--color-primary);
    margin-right: 5px;
    margin-bottom: 2px;
    background-color: var(--white);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.5);
    use {
      transform: scale(0.5);
      transform-origin: center;
    }
    &.play use {
      transform: scale(0.5) translateX(1px);
    }
  }

  @media screen and (min-width: 1200px) {
    text-align: left;
  }
}

.triggerSpeech {
  margin-right: 5px;
  color: var(--color);
  &:hover {
  color: var(--color);
  svg {
    background-color: var(--red-alt);
    fill: var(--white);
  }
  }
}