.container {
  padding: 1rem 0;
  margin-bottom: 1rem;
}

.items {
  display: flex;
  justify-content: center;
  flex-flow: row-reverse wrap;
  padding-left: 1rem;

  @media screen and (width >= 768px) {
    justify-content: flex-end;
    padding-left: unset;
  }
}

.item {
  width: 90px;
  height: 90px;
  position: relative;
  &:not(:last-of-type) {
    margin-left: -20px;
  }
}
